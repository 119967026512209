import {PageTitle} from 'src/app/components/Layout/header/HeaderTitle'
import {
  ActionButton,
  Content,
  ContentHeader,
  InputSearch,
  Paging,
  QueryState,
  QueryStateContent,
  Stack,
  FilterMenu,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  DialogRouter,
} from 'src/app/components'
import {safeArray} from 'src/app/utils/array-utils'
import {usePermissions} from 'src/app/hooks/permissions-hook'
import {AuditTrailModel} from '../models/AuditTrail.model'
import AuditTrailScreens from '../AuditTrailScreen'
import {useAuditTrail} from './AuditTrail.hook'
import {DialogContentAuditTrailDetail} from './DialogContent/DialogContentAuditTrailDetail.page'
import {useState} from 'react'
import dayjs from 'dayjs'
import {useHistory} from 'react-router-dom'
import {FilterMenuContentAuditTrail} from './FilterMenuContentAuditTrail/FilterMenuContentAuditTrail.page'

function AuditTrailPage() {
  const state = useAuditTrail()
  const history = useHistory()
  const {accessRedirect} = usePermissions()
  const [detailId, setDetailId] = useState<string>('')
  const [userAction, setUserAction] = useState<string>('')
  accessRedirect('audit_trail.read')

  const renderItem = (item: AuditTrailModel, index: number) => {
    return (
      <TableRow key={index}>
        <TableCell {...{index}} />
        <TableCell>{item?.fullname}</TableCell>
        <TableCell>{item?.roleName}</TableCell>
        <TableCell>{item?.menuLabel}</TableCell>
        <TableCell>{item?.actionLabel}</TableCell>
        <TableCell value={item?.updatedAt} renderValue={(_) => dayjs(_).format('DD/MM/YYYY HH:mm:ss')} />
        <TableCell action={true}>
          <ActionButton
            className="mx-auto"
            variant="detail"
            onClick={() => {setDetailId(item.requestId!); setUserAction(item.actionLabel!); history.push(AuditTrailScreens.DETAIL_AUDIT_TRAIL.PATH.replace(':user_action', item.actionLabel!).replace(':id', item.requestId!))}}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <PageTitle description={AuditTrailScreens.AUDIT_TRAIL.TITLE}>
        {AuditTrailScreens.AUDIT_TRAIL.TITLE}
      </PageTitle>
      <Content>
        <ContentHeader title={AuditTrailScreens.LIST_AUDIT_TRAIL.TITLE}>
        </ContentHeader>
        <Stack direction="row" justify="between" items="center" className="px-8 py-4">
          <InputSearch className="w-full flex items-center space-x-2" />
          <FilterMenu>
            <FilterMenuContentAuditTrail withRole={true} withMenuAction={true} withPeriod={true}/>
          </FilterMenu>
        </Stack>
        <QueryState query={state.auditTrail}>
          <div className="flex-1 px-8">
            <Table className="min-w-[1400px] w-full">
              <TableHead>
                <TableRow>
                  <TableCell index={true} />
                  <TableCell order="fullname">Username</TableCell>
                  <TableCell order="roleName">Role</TableCell>
                  <TableCell order="menuLabel">Menu</TableCell>
                  <TableCell order="actionLabel">User Action</TableCell>
                  <TableCell order="updatedAt">Date & Time</TableCell>
                  <TableCell action />
                </TableRow>
              </TableHead>
              <TableBody>{safeArray(state.auditTrail.data?.content).map(renderItem)}</TableBody>
            </Table>
            <Paging query={state.auditTrail} />
          </div>
          <QueryStateContent title="List Audit Trail is Empty" description="" />
        </QueryState>
        <DialogRouter path={AuditTrailScreens.DETAIL_AUDIT_TRAIL.PATH.replace(':user_action', userAction).replace(':id', detailId)} size="xl">
          <DialogContentAuditTrailDetail />
        </DialogRouter>
      </Content>
    </>
  )
}

export default AuditTrailPage
