import {
  useParams,
} from 'react-router-dom'

import {
  DatePicker,
  Label,
  MenuContent,
  Radio, Select, SelectAsync,
  Stack,
} from 'src/app/components'
import {getPeriodValue} from '../../../../components/Feedback/FilterMenu/FilterMenuContent.utils'
import {FilterMenuContentProps} from '../../../../components/Feedback/FilterMenu/FilterMenuContent.types'
import {getRole} from '../../../user_and_permissions/services/Role.service'
import {useFilterMenuContentAuditTrailHook} from './FilterMenuContentAuditTrail.hooks'

function FilterMenuContentAuditTrail(props: FilterMenuContentProps) {

  const params = useParams<{path: string}>()
  const state = useFilterMenuContentAuditTrailHook()
  const statusMaster = state.master.getStatusMaster(props)

  const renderRewardItem = (item: typeof state.master.rewardTypes[0]) => {
    return (
      <Radio
        label={item.label}
        {...state.filterContext.registerRadio({name: 'reward_types', value: item.value})}
        key={item.value}
      />
    )
  }

  const renderStatusItem = (item: typeof state.master.statuses[0]) => {
    return (
      <Radio
        {...state.filterContext.registerRadio({name: 'status', value: item.value})}
        label={item.label}
        key={item.value}
      />
    )
  }

  return (
    <MenuContent>
      {props.withPeriod && (
        <DatePicker
          placeholder="Select date"
          onChange={state.action.handleChangePeriod}
          format="DD/MM/YYYY"
          value={getPeriodValue(state.filterContext.draft?.period)}
          range={true}
          label="Period"
        />
      )}
      {props.withRole && (
        <>
          <div>
            <Label>Role</Label>
            <SelectAsync
              placeholder="Select role"
              fetchApi={getRole}
              isMulti={true}
              name="role_ids"
              payload={{statuses: ['ACTIVE']}}
              selectOption={(item) => ({
                label: item?.role_name!,
                value: item?.id,
              })}
              value={state.action.getSelectRoleValue()}
              onChangeValue={(state.action.handleSelectRole)}
            />
          </div>
        </>
      )}
      {props.withMenuAction && (
        <>
          <div>
            <Label>Menu</Label>
            <Select
              placeholder="Select Menu"
              name="menu_codes_ids"
              options={state.state.menuOptions}
              value={state.action.getSelectMenuValue()}
              onChangeValue={(selectedValue)=>{
                state.action.handleSelectMenu(selectedValue);
                state.filterContext.draft.action_codes_ids = ''
                state.filterContext.draft.action_names = ''
                state.action.handleSelectAction([])
                state.action.getSelectActionValue()
              }}
            />
          </div>
          <div>
            <Label>Action</Label>
            <Select
              placeholder="Select Actions"
              name="action_codes_ids"
              options={state.state.actionOptions}
              disabled={state.state.actionOptions.length === 0}
              value={state.action.getSelectActionValue()}
              onChangeValue={state.action.handleSelectAction}
            />
          </div>
        </>
      )}
    </MenuContent>
  )
}

FilterMenuContentAuditTrail.defaultProps = {
  withStatus: true,
  withRole: true,
  withMenuAction: true,
} as FilterMenuContentProps

export {FilterMenuContentAuditTrail}
