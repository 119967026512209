import { ScreenProps } from "src/app/models/screen-prop";

const AuditTrailScreens: ScreenProps = {
  AUDIT_TRAIL: {
    PATH: '/audit-trail',
    TITLE: 'Audit Trail',
    TITLE_ALT: 'Audit Trail',
    PERMISSION: 'audit_trail.read',
  },
  LIST_AUDIT_TRAIL: {
    PATH: '/audit-trail/list',
    TITLE: 'List Audit Trail',
    TITLE_ALT: 'List Audit Trail',
  },
  DETAIL_AUDIT_TRAIL: {
    PATH: '/audit-trail/list/detail/:user_action/:id',
    TITLE: 'Detail Audit Trail',
    TITLE_ALT: 'Detail Audit Trail',
  },
};

export default AuditTrailScreens;
