import {useQuery} from '@tanstack/react-query'
import {AxiosError, AxiosResponse} from 'axios'
import {createSelector} from 'reselect'
import {useApiParams, useUrlSearchParams} from 'src/app/hooks'
import {BaseResponse, Pagination, Response, ResponseList} from 'src/app/models/api.types'
import {AuditTrailFilterMenuContentState, AuditTrailModel} from '../models/AuditTrail.model'
import {
  getActionList,
  getAuditTrail,
  getAuditTrailDetail,
  URL_AUDIT_TRAIL,
} from '../services/AuditTrail.service'


// get report transaction
const selectAuditTrail = createSelector(
  (state: AxiosResponse<BaseResponse<Pagination<AuditTrailModel[]>>>) => state.data,
  (data: BaseResponse<Pagination<AuditTrailModel[]>>) => data.response_output?.list,
)

const selectAuditTrailDetail = createSelector(
  (state: AxiosResponse<BaseResponse<Response<AuditTrailModel>>>) => state.data,
  (data: BaseResponse<Response<AuditTrailModel>>) => data.response_output?.detail
)

export function useGetAuditTrail() {

  const [searchParams] = useUrlSearchParams<'role_ids' | 'role_names' | 'period'|'menu_codes_ids'|'menu_label'|'action_label'|'action_codes_ids'>();
  const defaultValues = {
    requestId: '',
    fullname: '',
    role_ids: searchParams.role_ids,
    menu_codes_ids: searchParams.menu_codes_ids,
    action_codes_ids: searchParams.action_codes_ids,
    createdAt: '',
    updatedAt: '',
    period: searchParams.period,
  }
  const [_params] = useApiParams({defaultValues})

  const params = {
    ..._params,
    // menuCodes change to array
    menuCodes: [_params.menu_codes_ids],
    actionCodes: [_params.action_codes_ids],
    roleIds: _params.role_ids,
  }

  // delete role_ids
  delete params.role_ids
  delete params.menu_codes_ids
  delete params.action_codes_ids


  return useQuery<
    AxiosResponse<BaseResponse<Pagination<AuditTrailModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<AuditTrailModel[]> | undefined
  >({
    keepPreviousData: false,
    queryKey: [URL_AUDIT_TRAIL, params],
    queryFn: (_) => getAuditTrail(params),
    select: selectAuditTrail,
  })
}

export const useGetAuditTrailDetail = (id?: string) => {
  return useQuery<
    AxiosResponse<BaseResponse<Response<AuditTrailModel>>>,
    AxiosError<BaseResponse<Response<any>>>,
    AuditTrailModel | undefined
  >({
    keepPreviousData: false,
    queryKey: [`${URL_AUDIT_TRAIL}/${id}`],
    queryFn: () => getAuditTrailDetail(id!),
    select: selectAuditTrailDetail,
    enabled: !!id
  })
}

export function useGetAuditTrailActionList(roleId: any) {

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<AuditTrailFilterMenuContentState[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<AuditTrailFilterMenuContentState[]> | undefined
  >({
    keepPreviousData: false,
    queryKey: [`${URL_AUDIT_TRAIL}/actions`, roleId],
    queryFn: () => getActionList(roleId),
  })
}