import clsx from 'clsx'
import { useMemo } from 'react'
import { useMasterLayout } from 'src/app/context/MasterContext'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import PromoScreens from 'src/app/modules/promo/PromoScreens'
import RewardsScreen from 'src/app/modules/rewards/RewardsScreens'
import DirectoryScreen from 'src/app/modules/directory/DirectoryScreen'
import SettingsScreens from 'src/app/modules/settings/SettingsScreens'
import UserAndPermissionsScreens from 'src/app/modules/user_and_permissions/UserAndPermissionsScreens'
import CustomerScreens from 'src/app/modules/customer/CustomerScreens'
import { ModulePermissionQuery } from 'src/app/modules/user_and_permissions/models/Permission.model'
import { UserModel } from 'src/app/modules/user_and_permissions/models/User.model'
import ReceiptReportScreens from 'src/app/modules/receipt_report/ReceiptReportScreens'
import ManageMobileAppScreens from 'src/app/modules/manage_mobile_app/ManageMobileAppScreens'
import { useGetProfile } from 'src/app/modules/user_and_permissions/providers/User.provider'
import { isMenuHiddden } from './AsideMenuData.utils'
import CampaignScreens from 'src/app/modules/campaign/CampaignScreens'
import DashboardScreens from 'src/app/modules/dashboard/DashboardScreens'
import PushNotificationScreens from 'src/app/modules/push_notification/PushNotificationScreens'
import ReportScreens from 'src/app/modules/report/ReportScreens'
import AuditTrailScreens from '../../../modules/audit_trail/AuditTrailScreen'

interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
}

const useMenus = (user?: UserModel): Menu[] => {
  const onlyTenant = user?.role?.permissions?.includes("manage_tenant_dashboard.read") && !user?.role?.permissions?.includes("manage_admin_dashboard.read")
  
  return useMemo(
    () =>
      [
        {
          children: [
            {
              to: onlyTenant ? DashboardScreens.TENANT_DASHBOARD.PATH.replace(':path', 'dashboard-rewards') : DashboardScreens.DASHBOARD.PATH,
              activePath: onlyTenant ? DashboardScreens.TENANT_DASHBOARD.PATH.replace(':path', '') : undefined,
              icon: '/media/icons/IconDashboard.svg',
              title: DashboardScreens.DASHBOARD.TITLE,
              hidden: isMenuHiddden<ModulePermissionQuery>(user?.role?.permissions ?? [],
                [
                  DashboardScreens.ADMIN_DASHBOARD.PERMISSION as ModulePermissionQuery,
                  DashboardScreens.TENANT_DASHBOARD.PERMISSION as ModulePermissionQuery,
                ]),
              children: onlyTenant ?
                undefined : [
                  {
                    to: DashboardScreens.ADMIN_DASHBOARD.PATH,
                    activePath: DashboardScreens.ADMIN_DASHBOARD.PATH.replace('/:path', ''),
                    title: DashboardScreens.ADMIN_DASHBOARD.TITLE,
                    hasBullet: true,
                    module_permissions: DashboardScreens.ADMIN_DASHBOARD.PERMISSION,
                    hidden: !user?.role?.permissions?.includes(DashboardScreens.ADMIN_DASHBOARD.PERMISSION as ModulePermissionQuery),
                  },
                  {
                    to: DashboardScreens.TENANT_DASHBOARD.PATH.replace(':path', 'dashboard-rewards'),
                    activePath: DashboardScreens.TENANT_DASHBOARD.PATH.replace(':path', ''),
                    title: DashboardScreens.TENANT_DASHBOARD.TITLE,
                    hasBullet: true,
                    module_permissions: DashboardScreens.TENANT_DASHBOARD.PERMISSION,
                    hidden: !user?.role?.permissions?.includes(DashboardScreens.TENANT_DASHBOARD.PERMISSION as ModulePermissionQuery),
                  },
                ]
            },
            {
              to: ReceiptReportScreens.LIST_RECEIPT_REPORT.PATH,
              icon: '/media/icons/IconReceiptReport.svg',
              title: ReceiptReportScreens.RECEIPT_REPORT.TITLE,
              module_permissions: ReceiptReportScreens.LIST_RECEIPT_REPORT.PERMISSION,
              hidden: !user?.role?.permissions?.includes(ReceiptReportScreens.LIST_RECEIPT_REPORT.PERMISSION as ModulePermissionQuery)
            },
            {
              to: CustomerScreens.CUSTOMER.PATH.replace(':segment', 'list-customer'),
              icon: '/media/icons/IconCustomer.svg',
              title: CustomerScreens.CUSTOMER.TITLE,
              module_permissions: CustomerScreens.CUSTOMER.PERMISSION,
              hidden: !user?.role?.permissions?.includes(CustomerScreens.CUSTOMER.PERMISSION as ModulePermissionQuery),
            },
            {
              to: RewardsScreen.REWARDS.PATH,
              activePath: RewardsScreen.REWARDS.PATH,
              icon: '/media/icons/IconRewards.svg',
              title: RewardsScreen.REWARDS.TITLE,
              hidden: isMenuHiddden<ModulePermissionQuery>(user?.role?.permissions ?? [],
                [
                  "manage_digital_reward.read",
                  "manage_physical_reward.read",
                  "manage_parking_reward.read",
                  "manage_birthday_reward.read",
                  "manage_featured_reward.read"
                ])
            },
            {
              to: PromoScreens.PROMO.PATH,
              activePath: PromoScreens.PROMO.PATH,
              icon: '/media/icons/IconPromo.svg',
              title: PromoScreens.PROMO.TITLE,
              hidden: isMenuHiddden<ModulePermissionQuery>(user?.role?.permissions ?? [],
                [
                  "manage_promo.read",
                  "manage_featured_promo.read"
                ])
            },
            {
              to: ManageMobileAppScreens.MANAGE_MOBILE_APP.PATH,
              icon: '/media/icons/IconManageMobileApp.svg',
              title: ManageMobileAppScreens.MANAGE_MOBILE_APP.TITLE,
              hidden: isMenuHiddden<ModulePermissionQuery>(user?.role?.permissions ?? [],
                [
                  "manage_banner.read",
                  "manage_featured_banner.read",
                  "manage_happening_now.read",
                  "manage_featured_happening_now.read",
                  "manage_version.read",
                  "manage_art.read",
                ]),
              children: [
                {
                  to: ManageMobileAppScreens.BANNER.PATH,
                  activePath: ManageMobileAppScreens.BANNER.PATH,
                  title: ManageMobileAppScreens.BANNER.TITLE,
                  hasBullet: true,
                  module_permissions: ManageMobileAppScreens.LIST_BANNER.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(ManageMobileAppScreens.LIST_BANNER.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: ManageMobileAppScreens.HAPPENING_NOW.PATH.replace(':path', 'index'),
                  activePath: ManageMobileAppScreens.HAPPENING_NOW.PATH.replace(':path', ''),
                  title: ManageMobileAppScreens.HAPPENING_NOW.TITLE,
                  hasBullet: true,
                  module_permissions: ManageMobileAppScreens.LIST_HAPPENING_NOW.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(ManageMobileAppScreens.LIST_HAPPENING_NOW.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: ManageMobileAppScreens.LIST_VERSION.PATH,
                  activePath: ManageMobileAppScreens.LIST_VERSION.PATH,
                  title: ManageMobileAppScreens.LIST_VERSION.TITLE,
                  hasBullet: true,
                  module_permissions: ManageMobileAppScreens.LIST_VERSION.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(ManageMobileAppScreens.LIST_VERSION.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: ManageMobileAppScreens.LIST_ART_FOR_EVERYBODY.PATH,
                  activePath: ManageMobileAppScreens.LIST_ART_FOR_EVERYBODY.PATH,
                  title: ManageMobileAppScreens.ART_FOR_EVERYBODY.TITLE,
                  hasBullet: true,
                  module_permissions: ManageMobileAppScreens.ART_FOR_EVERYBODY.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(ManageMobileAppScreens.ART_FOR_EVERYBODY.PERMISSION as ModulePermissionQuery),
                },
              ]
            },
            {
              to: PushNotificationScreens.LIST_PUSH_NOTIFICATION.PATH,
              activePath: PushNotificationScreens.PUSH_NOTIFICATION.PATH,
              icon: '/media/icons/IconPushNotification.svg',
              title: PushNotificationScreens.PUSH_NOTIFICATION.TITLE,
              module_permissions: PushNotificationScreens.PUSH_NOTIFICATION.PERMISSION,
              hidden: !user?.role?.permissions?.includes(PushNotificationScreens.PUSH_NOTIFICATION.PERMISSION as ModulePermissionQuery),
            },
            {
              to: CampaignScreens.LIST_CAMPAIGN.PATH.replace(':path', 'index'),
              activePath: CampaignScreens.LIST_CAMPAIGN.PATH.replace(':path', ''),
              icon: '/media/icons/IconCampaign.svg',
              title: CampaignScreens.CAMPAIGN.TITLE,
              module_permissions: CampaignScreens.CAMPAIGN.PERMISSION,
              hidden: !user?.role?.permissions?.includes(CampaignScreens.CAMPAIGN.PERMISSION as ModulePermissionQuery),
            },
            {
              to: DirectoryScreen.DIRECTORY.PATH,
              icon: '/media/icons/IconDirectory.svg',
              title: DirectoryScreen.DIRECTORY.TITLE,
              module_permissions: DirectoryScreen.DIRECTORY.PERMISSION,
              hidden: isMenuHiddden<ModulePermissionQuery>(user?.role?.permissions ?? [], [
                DirectoryScreen.MALL.PERMISSION as ModulePermissionQuery,
                DirectoryScreen.STORE_CATEGORY.PERMISSION as ModulePermissionQuery,
                DirectoryScreen.STORE.PERMISSION as ModulePermissionQuery
              ]),
              children: [
                {
                  to: DirectoryScreen.MALL.PATH,
                  activePath: DirectoryScreen.MALL.PATH,
                  title: DirectoryScreen.MALL.TITLE,
                  hasBullet: true,
                  module_permissions: DirectoryScreen.MALL.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(DirectoryScreen.MALL.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: DirectoryScreen.STORE_CATEGORY.PATH,
                  activePath: DirectoryScreen.STORE_CATEGORY.PATH,
                  title: DirectoryScreen.STORE_CATEGORY.TITLE,
                  hasBullet: true,
                  module_permissions: DirectoryScreen.STORE_CATEGORY.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(DirectoryScreen.STORE_CATEGORY.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: DirectoryScreen.STORE.PATH,
                  activePath: DirectoryScreen.STORE.PATH,
                  title: DirectoryScreen.STORE.TITLE,
                  hasBullet: true,
                  module_permissions: DirectoryScreen.STORE.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(DirectoryScreen.STORE.PERMISSION as ModulePermissionQuery),
                }
              ]
            },
            {
              to: UserAndPermissionsScreens.USER_AND_PERMISSIONS.PATH,
              icon: '/media/icons/IconUserAndPermissions.svg',
              title: UserAndPermissionsScreens.USER_AND_PERMISSIONS.TITLE,
              hidden: isMenuHiddden<ModulePermissionQuery>(user?.role?.permissions ?? [], [
                UserAndPermissionsScreens.LIST_ROLE.PERMISSION as ModulePermissionQuery,
                UserAndPermissionsScreens.LIST_USER.PERMISSION as ModulePermissionQuery
              ]),
              children: [
                {
                  to: UserAndPermissionsScreens.LIST_ROLE.PATH,
                  activePath: UserAndPermissionsScreens.LIST_ROLE.PATH,
                  title: UserAndPermissionsScreens.LIST_ROLE.TITLE,
                  hasBullet: true,
                  module_permissions: UserAndPermissionsScreens.LIST_ROLE.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(UserAndPermissionsScreens.LIST_ROLE.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: UserAndPermissionsScreens.LIST_USER.PATH,
                  activePath: UserAndPermissionsScreens.LIST_USER.PATH,
                  title: UserAndPermissionsScreens.LIST_USER.TITLE,
                  hasBullet: true,
                  module_permissions: UserAndPermissionsScreens.LIST_USER.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(UserAndPermissionsScreens.LIST_USER.PERMISSION as ModulePermissionQuery),
                }
              ]
            },
            {
              to: SettingsScreens.SETTINGS.PATH,
              icon: '/media/icons/IconSetting.svg',
              title: SettingsScreens.SETTINGS.TITLE,
              hidden: isMenuHiddden<ModulePermissionQuery>(user?.role?.permissions ?? [], [
                SettingsScreens.LIST_PAYMENT_METHOD.PERMISSION as ModulePermissionQuery,
                SettingsScreens.LIST_PAYMENT_CATEGORY.PERMISSION as ModulePermissionQuery,
                SettingsScreens.LIST_FAQ.PERMISSION as ModulePermissionQuery,
                SettingsScreens.LIST_FAQ.PERMISSION as ModulePermissionQuery,
                SettingsScreens.TERMS_AND_CONDITIONS.PERMISSION as ModulePermissionQuery,
                SettingsScreens.ABOUT_US.PERMISSION as ModulePermissionQuery,
                SettingsScreens.PRIVACY_POLICY.PERMISSION as ModulePermissionQuery
              ]),
              children: [
                {
                  to: SettingsScreens.LIST_PAYMENT_METHOD.PATH,
                  activePath: SettingsScreens.LIST_PAYMENT_METHOD.PATH,
                  title: SettingsScreens.LIST_PAYMENT_METHOD.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.LIST_PAYMENT_METHOD.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(SettingsScreens.LIST_PAYMENT_METHOD.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: SettingsScreens.LIST_PAYMENT_CATEGORY.PATH,
                  activePath: SettingsScreens.LIST_PAYMENT_CATEGORY.PATH,
                  title: SettingsScreens.LIST_PAYMENT_CATEGORY.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.LIST_PAYMENT_CATEGORY.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(SettingsScreens.LIST_PAYMENT_CATEGORY.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: SettingsScreens.LIST_FAQ.PATH,
                  activePath: SettingsScreens.LIST_FAQ.PATH,
                  title: SettingsScreens.LIST_FAQ.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.LIST_FAQ.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(SettingsScreens.LIST_FAQ.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: SettingsScreens.TERMS_AND_CONDITIONS.PATH,
                  activePath: SettingsScreens.TERMS_AND_CONDITIONS.PATH,
                  title: SettingsScreens.TERMS_AND_CONDITIONS.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.TERMS_AND_CONDITIONS.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(SettingsScreens.TERMS_AND_CONDITIONS.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: SettingsScreens.ABOUT_US.PATH,
                  activePath: SettingsScreens.ABOUT_US.PATH,
                  title: SettingsScreens.ABOUT_US.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.ABOUT_US.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(SettingsScreens.ABOUT_US.PERMISSION as ModulePermissionQuery),
                },
                {
                  to: SettingsScreens.PRIVACY_POLICY.PATH,
                  activePath: SettingsScreens.PRIVACY_POLICY.PATH,
                  title: SettingsScreens.PRIVACY_POLICY.TITLE,
                  hasBullet: true,
                  module_permissions: SettingsScreens.PRIVACY_POLICY.PERMISSION,
                  hidden: !user?.role?.permissions?.includes(SettingsScreens.PRIVACY_POLICY.PERMISSION as ModulePermissionQuery),
                },
              ]
            },
            {
              to: ReportScreens.LIST_REPORT.PATH.replace('/:path', ''),
              activePath: ReportScreens.LIST_REPORT.PATH.replace('/:path', ''),
              icon: '/media/icons/IconReport.svg',
              title: ReportScreens.REPORT.TITLE,
              hidden: isMenuHiddden<ModulePermissionQuery>(user?.role?.permissions ?? [],
                [
                  "report_profiles.read",
                  "report_promos.read",
                  "report_rewards.read",
                  "report_store.read",
                  "report_wifi.read"
                ]),
            },
            {
              to: AuditTrailScreens.LIST_AUDIT_TRAIL.PATH.replace('/:path', ''),
              activePath: AuditTrailScreens.LIST_AUDIT_TRAIL.PATH.replace('/:path', ''),
              icon: '/media/icons/IconAuditTrail.svg',
              title: AuditTrailScreens.AUDIT_TRAIL.TITLE,
              hidden: !user?.role?.permissions?.includes(AuditTrailScreens.AUDIT_TRAIL.PERMISSION as ModulePermissionQuery),
            },
          ],
        },
      ] as Menu[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )
}

export const GeneratedMenu: React.FC<{ menu: Menu }> = ({ menu }) => {
  const { minimize } = useMasterLayout()

  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0' key="0">
          <div className='px-6 pb-2'>
            <span
              className={clsx('tracking-widest text-white uppercase text-fs-10', {
                hidden: minimize,
              })}
            >
              {menu.title}
            </span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
      hasBullet={menu.hasBullet}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

export const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))


  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user = useGetProfile()
  const menus = useMenus(user.data)
  const generated = useMemo(() => filterMenus(menus, (menu) => !Boolean(menu.hidden)), [menus])

  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
