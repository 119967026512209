import React, {RefObject} from 'react'
import {
  Button,
  Dialog,
  DialogAction,
  DialogContent,
  DialogRef,
  DialogTitle, Table, TableBody,
  TableCell, TableHead,
  TableRow,
} from 'src/app/components'
import {CampaignModel} from '../../../models/Campaign.model'
import {hasArray} from '../../../../../utils/array-utils'

type CampaignValidateDialogProps = {
  dialogRef?: RefObject<DialogRef>
  dataCampaign: any[]
  alertMessageCampaign: string
}

const renderItem = (item: CampaignModel) => {
  return (
    <TableRow key={item.id}>
      <TableCell value={item.campaign_type} renderValue={(_: any) => `${_?.charAt(0).toUpperCase()}${_?.slice(1).toLowerCase()} Point`} />
      <TableCell
        renderValue={(_) => _.map((__) => __?.merchant_name)}
        validate={hasArray}
        value={item.merchants}
      />
      <TableCell
        renderValue={(_: any) => `${item?.start_time || '00:00'} - ${item?.end_time || '24:00'}`}
        value={item?.start_time}
      />
    </TableRow>
  )
}

const CampaignValidateDialog = (props: CampaignValidateDialogProps) => {
  return (
    <Dialog
      closeOnBackdropClicked={true}
      size="md"
      ref={props.dialogRef}
      {...props}
    >
      <DialogTitle title={'Failed to Create Campaign'} />
      <DialogContent>
        <p className="bg-danger-100 p-3 rounded text-black text-xs flex items-center gap-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7475 9.4149 20.7195 6.93639 18.8916 5.10845C17.0636 3.28051 14.5851 2.25248 12 2.25ZM11.25 7.5C11.25 7.30109 11.329 7.11032 11.4697 6.96967C11.6103 6.82902 11.8011 6.75 12 6.75C12.1989 6.75 12.3897 6.82902 12.5303 6.96967C12.671 7.11032 12.75 7.30109 12.75 7.5V12.75C12.75 12.9489 12.671 13.1397 12.5303 13.2803C12.3897 13.421 12.1989 13.5 12 13.5C11.8011 13.5 11.6103 13.421 11.4697 13.2803C11.329 13.1397 11.25 12.9489 11.25 12.75V7.5ZM12 17.25C11.7775 17.25 11.56 17.184 11.375 17.0604C11.19 16.9368 11.0458 16.7611 10.9606 16.5555C10.8755 16.35 10.8532 16.1238 10.8966 15.9055C10.94 15.6873 11.0472 15.4868 11.2045 15.3295C11.3618 15.1722 11.5623 15.065 11.7805 15.0216C11.9988 14.9782 12.225 15.0005 12.4305 15.0856C12.6361 15.1708 12.8118 15.315 12.9354 15.5C13.059 15.685 13.125 15.9025 13.125 16.125C13.125 16.4234 13.0065 16.7095 12.7955 16.9205C12.5845 17.1315 12.2984 17.25 12 17.25Z"
              fill="#FF5454" />
          </svg>

          {props.alertMessageCampaign || 'We find overlapping campaign item with existing campaign data, please update the data accordingly.'}
        </p>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Campaign Type</TableCell>
              <TableCell>Store</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.dataCampaign?.map(renderItem)}
          </TableBody>
        </Table>
      </DialogContent>

      <DialogAction>
        <Button variant="outlined" onClick={() => {
          props.dialogRef?.current?.hide()
        }}>
          Close
        </Button>
      </DialogAction>
    </Dialog>
  )
}

export default CampaignValidateDialog