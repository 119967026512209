import React from 'react'
import {RegisterOptions, UseFormReturn} from 'react-hook-form'
import {InputSelectAsync} from '../InputSelectAsync/InputSelectAsync.page'
import {getMall} from '../../../modules/directory/services/Mall.service'

type InputSelectMallProps = {
  form: UseFormReturn<any, any, undefined>
  isMulti?: boolean
  rule?: RegisterOptions<any, string>
}

const InputSelectMall = (props: InputSelectMallProps) => {

  return (
    <InputSelectAsync
      placeholder="Select mall"
      fetchApi={getMall}
      payload={{statuses: ['ACTIVE']}}
      rule={props.rule ?? {required: true}}
      name={props.isMulti ? 'directories' : 'directory'}
      form={props.form}
      selectOption={(item) => ({
        label: item.directory_name!,
        value: item.id!,
      })}
      isMulti={props.isMulti}
      className="col-span-2"
    />
  )
}

export default InputSelectMall