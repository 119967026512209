import dayjs from 'dayjs'

import CampaignScreens from '../../CampaignScreens'

import {
  usePermissions,
} from 'src/app/hooks/permissions-hook'
import {
  AddRoundedIcon,
} from 'src/app/components/Icons'
import {
  ActionButton,
  Button,
  ChipStatus,
  Content,
  ContentHeader,
  FilterMenu,
  InputSearch,
  Paging,
  QueryState,
  QueryStateContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'src/app/components'
import {
  useCampaignHooks,
} from './Campaign.hooks'
import {
  hasArray,
} from 'src/app/utils/array-utils'
import {
  CampaignModel,
} from '../../models/Campaign.model'
import {
  FilterMenuContent,
} from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.page'

export function Campaign() {

  const permission = usePermissions()
  const state = useCampaignHooks()

  const renderItem = (item: CampaignModel, index: number) => {
    return (
      <TableRow key={item.id}>
        <TableCell {...{index}} />
        <TableCell value={item?.campaign_type} />
        <TableCell className="whitespace-nowrap" value={item?.directory?.directory_name} />
        <TableCell
          renderValue={(_) => _.map((__) => __?.category_name)}
          validate={hasArray}
          value={item.merchant_categories}
          isTruncate={true}
        />
        <TableCell
          renderValue={(_) => _.map((__) => __?.merchant_name)}
          validate={hasArray}
          value={item.merchants}
          isTruncate={true}
        />
        <TableCell
          renderValue={(_) => dayjs(_).format('DD/MM/YYYY')}
          className="w-[145px]"
          value={item?.start_date}
        />
        <TableCell
          renderValue={(_) => dayjs(_).format('DD/MM/YYYY')}
          className="w-[145px]"
          value={item?.end_date}
        />
        <TableCell>
          <ChipStatus state={item?.status} />
        </TableCell>
        <TableCell action={true}>
          <ActionButton
            className="mx-auto"
            variant="detail"
            to={CampaignScreens.DETAIL_CAMPAIGN.PATH.replace(':id', item.id!)}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Content>
      <ContentHeader title="List Active Campaign">
        {permission.setVisibleByAccess(
          ['campaign.create'],
          <Button
            startIcon={AddRoundedIcon}
            to={CampaignScreens.ADD_CAMPAIGN.PATH}
          >
            Add Campaign
          </Button>,
        )}
      </ContentHeader>

      <Stack
        direction="row"
        justify="between"
        items="center"
        className="px-6 py-4"
      >
        <InputSearch />
        <FilterMenu>
          <FilterMenuContent withStatusInactive={false} />
        </FilterMenu>
      </Stack>

      <QueryState query={state.campaign}>
        <div className="px-8">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell index />
                <TableCell>Campaign Type</TableCell>
                <TableCell>Mall</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Store</TableCell>
                <TableCell order="start_date" className="w-[145px]">Start Date</TableCell>
                <TableCell order="end_date" className="w-[145px]">End Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell action={true} className="w-[100px]" />
              </TableRow>
            </TableHead>
            <TableBody>
              {state.campaign.data?.content?.map(renderItem)}
            </TableBody>
          </Table>
          <Paging query={state.campaign} />
        </div>
        <QueryStateContent title="List Active Campaign is Empty" />
      </QueryState>
    </Content>
  )
}
