import axios from "axios";

import {
  BaseResponse,
  Pagination,
  Response
} from "src/app/models/api.types";
import {
  MallModel
} from "../models/Mall.model";

export const URL_MALL = '/v1/cms/directories';

export async function getMall(params: any, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<MallModel[]>>>(URL_MALL, {
    headers: { 'App-Token': process.env.REACT_APP_API_TOKEN },
    params,
    signal
  });
}

export async function getMallDetail(id: string, signal?: AbortSignal) {
  return axios.get<BaseResponse<Response<MallModel>>>(`${URL_MALL}/${id}`, {
    headers: { 'App-Token': process.env.REACT_APP_API_TOKEN },
    signal
  });
}

export function saveMall(params: MallModel) {
  if (!params.id) {
    return axios.post<BaseResponse<Response<MallModel>>>(URL_MALL, params, {
      headers: { 'App-Token': process.env.REACT_APP_API_TOKEN }
    });
  }
  return axios.put<BaseResponse<Response<MallModel>>>(`${URL_MALL}/${params.id}`, params, {
    headers: { 'App-Token': process.env.REACT_APP_API_TOKEN }
  });
}

export async function deleteMall(id: string) {
  return axios.delete<BaseResponse<Response<{}>>>(`${URL_MALL}/${id}`, {
    headers: { 'App-Token': process.env.REACT_APP_API_TOKEN }
  });
}
