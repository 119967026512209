import {DateObject} from 'react-multi-date-picker'

import {useFilterMenuContext} from 'src/app/components'
import {useUrlSearchParams} from 'src/app/hooks'
import {months, rewardTypes, statuses} from '../../../../components/Feedback/FilterMenu/FilterMenuContent.templates'
import {FilterMenuContentProps} from '../../../../components/Feedback/FilterMenu/FilterMenuContent.types'
import {convertPeriod} from '../../../../components/Feedback/FilterMenu/FilterMenuContent.utils'
import {RoleModel} from '../../../user_and_permissions/models/Role.model'
import {safeArray} from '../../../../utils/array-utils'
import {useEffect, useState} from 'react'
import {useGetAuditTrailActionList} from '../../providers/AuditTrail.provider'
import {useLocation} from 'react-router-dom'

export function useFilterMenuContentAuditTrailHook() {

  const [searchParams] = useUrlSearchParams<'reward_types' | 'status' | 'months' | 'period' | 'directory_ids' | 'role_ids' | 'role_names' | 'menu_codes_ids' | 'menu_names' | 'action_codes_ids' | 'action_names'>()
  const [menuOptions, setMenuOptions] = useState<any>([])
  const [actionOptions, setActionOptions] = useState<any>([])
  const [selectedMenu, setSelectedMenu] = useState<any>([])
  const [selectedRoleIds, setSelectedRoleIds] = useState<string[]>([''])
  const location = useLocation()
  const defaultFilter = {
    directory_ids: searchParams.directory_ids,
    reward_types: searchParams.reward_types,
    status: searchParams.status,
    months: searchParams.months,
    period: searchParams.period,
    role_ids: searchParams.role_ids,
    action_codes_ids: searchParams.action_codes_ids,
    menu_codes_ids: searchParams.menu_codes_ids,
  }

  const filterContext = useFilterMenuContext<typeof searchParams>({defaultValues: defaultFilter})

  useEffect(() => {
    if (filterContext.isVisible) {
      filterContext.updateDraft({
        role_names: searchParams.role_names,
        role_ids: searchParams.role_ids,
        menu_codes_ids: searchParams.menu_codes_ids,
        menu_names: searchParams.menu_names,
        action_codes_ids: searchParams.action_codes_ids,
        action_names: searchParams.action_names,
      })
    }
  }, [filterContext.isVisible])

  // Check if the URL contains 'audit-trail'
  const isAuditTrailPage = location.pathname.includes('audit-trail')

  // Use the custom hook at the top level
  const {data, isLoading, error}: {
    data: any,
    isLoading: any,
    error: any
  } = useGetAuditTrailActionList(selectedRoleIds)

  useEffect(() => {
    if (isAuditTrailPage && data && data.data.response_output.list.content) {
      // Populate menu options
      const menuOptions = data.data.response_output.list.content.map((menu: any) => ({
        label: menu.menu_label,
        value: menu.menu_code,
      }))
      setMenuOptions(menuOptions)
    }
  }, [data, error]) // Effect to handle data changes

  const getStatusMaster = (params: FilterMenuContentProps) => {
    return statuses.filter((item) => {
      if (item.value === 'INACTIVE') {
        return params.withStatusInactive
      }
      if (item.value === 'PENDING') {
        return params.withStatusPending
      }
      if (item.value === 'ACTIVE') {
        return params.withStatusActive
      }
      return true
    })
  }

  const handleChangePeriod = (value: DateObject | DateObject[] | null) => {
    filterContext.updateDraft({period: convertPeriod(value)})
  }

  const handleChangeMonth = (value: DateObject | null) => {
    filterContext.updateDraft({months: value?.toFirstOfMonth().format('YYYY-MM-DD')})
  }

  const handleSelectRole = async (value: RoleModel | RoleModel[]) => {
    // reset selected menu and action
    setMenuOptions([]) // Clear menu options
    setActionOptions([]) // Clear action options
    setSelectedMenu(null) // Clear selected menu
    filterContext.updateDraft({
      menu_codes_ids: '',
      menu_names: '',
      action_codes_ids: '',
      action_names: '',
    }) // Clear selected menu and action in context

    if (Array.isArray(value)) {
      const names = value.reduce((_, __) => `${_}.${__.role_name}`, '')
      const ids = value.reduce((_, __) => `${_}.${__.id}`, '')
      filterContext.updateDraft({
        role_names: names,
        role_ids: ids,
      })
      if (ids) {
        let idsArray = ids.split('.')
        idsArray = idsArray.filter((id: string) => id !== '')
        setSelectedRoleIds(idsArray) // Update the selected role IDs state
      }

    }
  }

  const getSelectRoleValue = () => {
    const names = (filterContext.draft.role_names)?.split('.')
    const ids = safeArray(filterContext.draft.role_ids?.split('.'))
    return safeArray(names).reduce((stack, item, index) => {
      return !item ? stack : [...stack, {role_name: item, id: ids[index]}]
    }, [] as RoleModel[])
  }

  const getSelectMenuValue = () => {
    const names = filterContext.draft.menu_names
    const ids = filterContext.draft.menu_codes_ids
    return names && ids ? {label: names, value: ids} : null
  }

  const getSelectActionValue = () => {
    const names = filterContext.draft.action_names
    const ids = filterContext.draft.action_codes_ids
    return names && ids ? {label: names, value: ids} : null
  }

  const handleSelectMenu = (menu: any) => {
    setSelectedMenu(menu.value)

    const selectedMenuActions = data.data.response_output.list.content.find((m: any) => m.menu_code === menu.value)?.actions || []
    if (selectedMenuActions.length > 0) {
      const formattedActions = selectedMenuActions.map((action: any) => ({
        label: action.action_label,
        value: action.action_code,
      }))
      setActionOptions(formattedActions) // Populate the action options for the selected menu
    } else {
      setActionOptions([])
    }

    filterContext.updateDraft({menu_codes_ids: menu.value, menu_names: menu.label})
  }

  const handleSelectAction = (action: any) => {
    filterContext.updateDraft({action_codes_ids: action.value, action_names: action.label})
  }

  return ({
    filterContext,
    state: {
      menuOptions,
      actionOptions,
      isLoading, // Expose loading state
      selectedMenu,
    },
    action: {
      handleChangePeriod,
      handleChangeMonth,
      handleSelectRole,
      getSelectRoleValue,
      getSelectMenuValue,
      getSelectActionValue,
      handleSelectMenu,
      handleSelectAction,
    },
    master: {
      getStatusMaster,
      rewardTypes,
      statuses,
      months,
    },
  })
}
