import {
  BackButton,
  Button,
  Content,
  ContentAction,
  ContentHeader,
  ControlLabel,
  Divider, Radio,
  Stack,
} from 'src/app/components'
import {
  HookFormProvider,
  InputDatePicker, InputRadioGroup,
  InputSelect,
  InputSelectAsync, InputTimePicker,
} from 'src/app/components/HookForm'
import {CampaignFormProps} from './CampaignForm.types'
import {useCampaignFormHooks} from './CampaignForm.hooks'
import {getStoreCategory} from 'src/app/modules/directory/services/StoreCategory.service'
import {getPaymentMethod} from 'src/app/modules/settings/services/PaymentMethod.service'
import {getEnabledFields} from './CampaignForm.utils'
import InputSelectMall from 'src/app/components/HookForm/InputSelect/InputSelect.mall'
import SelectAllCheckbox from 'src/app/components/Inputs/SelectAllCheckbox/SelectAllCheckbox.page'
import isEmpty from 'lodash/isEmpty'
import {getPaymentCategory} from '../../../../settings/services/PaymentCategory.service'
import CampaignValidateDialog from '../CampaignValidateDialog/CampaignValidateDialog.page'

export function CampaignForm(props: CampaignFormProps) {
  const state = useCampaignFormHooks()
  const enabledFields = getEnabledFields(state.campaign.data?.status)

  return (
    <>
      <BackButton />
      <Content>
        <ContentHeader title={props.title} />
        <HookFormProvider
          mutation={state.saveCampaign}
          enables={enabledFields}
          query={state.campaign}
          form={state.form}
        >
          <Stack className="p-6">
            <ControlLabel label="Campaign Type" required={true}>
              <InputRadioGroup form={state.form} name="campaign_type">
                <Radio key={'double'} label="Double Point" value="DOUBLE" required />
                <Radio key={'triple'} label="Triple Point" value="TRIPLE" required />
              </InputRadioGroup>
            </ControlLabel>
            <ControlLabel label="Mall">
              <InputSelectMall form={state.form} rule={{required: true}} />
            </ControlLabel>
            <ControlLabel label="Period" required={true}>
              <Stack direction="row" items="center">
                <InputDatePicker
                  placeholder="Select start date"
                  minDate={state.state.minPeriod.minStartDate}
                  rule={{required: true}}
                  name="start_date"
                  form={state.form}
                />
                <Divider className="w-[50px] border-t-2" color="primary" />
                <InputDatePicker
                  placeholder="Select end date"
                  minDate={state.state.minPeriod.minEndDate}
                  rule={{required: true}}
                  name="end_date"
                  form={state.form}
                />
              </Stack>
            </ControlLabel>
            <ControlLabel label="Time" required={true}>
              <InputRadioGroup
                form={state.form}
                name="time_type"
                onChange={() => {
                state.form.setValue('start_time', '')
                state.form.setValue('end_time', '')
              }}>
                <Radio key={'double'} label="24 hour" value="FULL_DAY" required />
                <Radio key={'triple'} label="Specific Time Range" value="SPECIFIC_TIME" required />
              </InputRadioGroup>
            </ControlLabel>
            {state.form.watch('time_type') === 'SPECIFIC_TIME' && (
              <ControlLabel label="Time" required={true}>
                <Stack direction="row" items="center">
                  <InputTimePicker
                    placeholder="Select start time"
                    className="flex-1"
                    rule={{required: true}}
                    name="start_time"
                  />
                  <Divider className="w-[24px] border-t-2" />
                  <InputTimePicker
                    placeholder="Select end time"
                    className="flex-1"
                    rule={{required: true}}
                    name="end_time"
                  />
                </Stack>
              </ControlLabel>
            )}
            <SelectAllCheckbox
              label="Select All Store Category"
              onChecked={(selected) => {
                state.form.setValue('merchant_categories', state?.storeCategory?.data?.content!)
                state.form.setValue('merchants', state?.store?.data?.content!)
              }}
              onUnChecked={() => {
                state.form.setValue('merchant_categories', [])
                state.form.setValue('merchants', [])
              }}
              checked={
                (state.form.watch('merchant_categories').length === state.storeCategory.data?.content?.length) &&
                (state.form.watch('merchants').length === state.store.data?.content?.length)
              }
              options={state.storeCategory.data?.content}
              selectOption={(_) => ({
                label: _.category_name!,
                value: _.id!,
              })}
              disabled={isEmpty(state.form.watch('directory'))}
            />
            <ControlLabel label="Store Category">
              <InputSelectAsync
                placeholder="Select store category"
                disabled={!state.state.directory?.id}
                fetchApi={getStoreCategory}
                payload={{directory_ids: [state.state.directory?.id], statuses: ['ACTIVE']}}
                onChangeValue={state.action.handleSyncStoreCategory}
                isMulti={true}
                form={state.form}
                name="merchant_categories"
                selectOption={(_) => ({
                  label: _.category_name!,
                  value: _.id!,
                })}
              />
            </ControlLabel>

            <ControlLabel label="Store">
              <InputSelect
                onChangeValue={state.action.handleSyncStore}
                placeholder="Select store"
                isMulti={true}
                options={state.store.data?.content}
                rule={{required: true}}
                form={state.form}
                name="merchants"
                selectOption={(_) => ({
                  label: _.merchant_name!,
                  value: _.id!,
                })}
              />
            </ControlLabel>
            <ControlLabel label="Payment Category" required={true}>
              <InputSelectAsync
                placeholder="Type to add"
                fetchApi={getPaymentCategory}
                payload={{statuses: ['ACTIVE']}}
                isMulti={true}
                form={state.form}
                required={true}
                name="payment_categories"
                selectOption={(_) => ({
                  label: _.payment_category_name!,
                  value: _.id,
                })}
              />
            </ControlLabel>
            <ControlLabel label="Payment Method" required={true}>
              <InputSelectAsync
                placeholder="Type to add"
                fetchApi={getPaymentMethod}
                payload={{statuses: ['ACTIVE']}}
                isMulti={true}
                form={state.form}
                required={true}
                name="payment_methods"
                selectOption={(_) => ({
                  label: _.method_name!,
                  value: _.id,
                })}
              />
            </ControlLabel>
          </Stack>
          <ContentAction mutation={state.saveCampaign}>
            <Button>Cancel</Button>
            <Button onClick={state.action.handleSubmit}>Save Campaign</Button>
          </ContentAction>
        </HookFormProvider>
        <CampaignValidateDialog dialogRef={state.campaignValidateDialogRef} dataCampaign={state.state.campaignValidateDataDialog} alertMessageCampaign={state.state.campaignValidateAlertMessage}/>
      </Content>
    </>
  )
}
